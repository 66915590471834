<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">더미</h2>
      </div>
      <div class="body_section"></div>
    </div>
    <Sticky>
      <div class="area_left">
        <button class="btn_primary btn_large">목록</button>
      </div>
      <div class="area_right">
        <button class="btn_primary btn_large">버튼2</button>
      </div>
    </Sticky>
    <template v-slot:popup> </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import Sticky from "@/components/layout/content/Sticky.vue";

export default {
  name: "Dummy",
  components: {
    PageWithLayout,
    Sticky,
  },
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
